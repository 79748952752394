import PropTypes from "prop-types"
import React from "react"

import Image from "../components/image"

const CenterHomeBanner = ({ image }) => {
  return (
    <div
      id="top-banner"
      className="container-fluid horizontal-section-container clearfix"
    >
      <div className="row">
        <div className="col-xs-12">
          <Image image={image} />
        </div>
      </div>
    </div>
  )
}
CenterHomeBanner.propTypes = {
  image: PropTypes.object,
}

export default CenterHomeBanner
