import PropTypes from "prop-types"
import React from "react"

import { Link } from "gatsby"

const centerBreadCrumbs = ({ centerName, centerUrl, subPageName }) => {
  return (
    <div
      id="breadcrumbs-container"
      className="container-fluid horizontal-section-container hidden-xs clearfix"
    >
      <div className="row">
        <div className="col-xs-12">
          <ol className="breadcrumb">
            <li>
              <Link to="/">Centre Selection</Link>
            </li>
            <li>
              <Link to={centerUrl}>{centerName}</Link>
            </li>
            {subPageName && <li>{subPageName}</li>}
          </ol>
        </div>
      </div>
    </div>
  )
}
centerBreadCrumbs.propTypes = {
  centerName: PropTypes.string,
  centerUrl: PropTypes.string,
}

export default centerBreadCrumbs
