import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../components/seo"
import CenterHomeBanner from "../components/centerHomeBanner"
import CenterBreadCrumbs from "../components/centerBreadCrumbs"

const Kiddinn = () => {
  const data = useStaticQuery(graphql`
    query {
      jki: file(relativePath: { eq: "jki-slide1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1068) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO title="Kidd Inn" />

      <CenterHomeBanner image={data.jki.childImageSharp.fluid} />

      <CenterBreadCrumbs centerName="Kidd Inn" centerUrl="/kiddinn/" />

      <div className="container-fluid horizontal-section-container clearfix">
        <div className="row">
          <div className="col-sm-12">
            <div
              id="main-page-content"
              className="section-container main-page-content clearfix"
            >
              <div className="section-content">
                <h1 className="page_title">Welcome</h1>

                <h4>Kidd Inn Early Learning Centre Ltd</h4>

                <p>
                  Founded in 2001, this is a purpose built centre catering for
                  85 children from 0-5 years of age.
                </p>

                <p>
                  Kidd Inn is set in park like grounds with an impressive
                  outdoor area for children to explore.
                </p>

                <p>
                  49 Kaurilands Road, Titirangi – Hours of operation 7.30am –
                  5.00pm
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid horizontal-section-container clearfix">
        <div className="row">
          <div className="col-sm-10">
            <div id="footer-contact-us" className="section-container clearfix">
              <span className="section-header footer-header">
                <h5 className="text">Contact Us</h5>
              </span>

              <div className="section-content clearfix">
                <div className="vertical-simple-list contact-list">
                  <div className="col-md-6">
                    <div className="item clearfix">
                      <div className="item-content">
                        <div className="icon">
                          <span className="glyphicon glyphicon-map-marker"></span>
                        </div>
                        <h6 className="title">
                          49 Kaurilands Road, Titirangi
                          <br />
                          Hours of operation 7.30am – 5.00pm
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="item clearfix">
                      <div className="item-content">
                        <div className="icon">
                          <span className="glyphicon glyphicon-earphone"></span>
                        </div>
                        <h6 className="title">
                          <a href="tel:09 8189398">(+649) 8189398</a>
                        </h6>
                      </div>
                    </div>

                    <div className="item clearfix">
                      <div className="item-content">
                        <div className="icon">
                          <span className="glyphicon glyphicon-envelope"></span>
                        </div>
                        <h6 className="title">
                          <a
                            href="mailto:kiddinn@xtra.co.nz"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            kiddinn@xtra.co.nz
                          </a>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Kiddinn
